import { Article } from '@generated'
import { imgix } from '@lib/imigx'

export function getGassanProductSchemaDetails(data: Article): any {
  let image = ''

  if (data && data.images && data.images.length > 0) {
    image = imgix(data.images[0] as string, { width: 750 })
  }

  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: data.supplierNr,
    image,
    description: data.description,
    brand: {
      '@type': 'Brand',
      name: data.brand,
    },
  }
}
