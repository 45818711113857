import { getGroupedOpeningHours } from './get-grouped-opening-hours'
import { getUrlSlugInLocale } from '@lib/get-url-slug-in-locale'
import { Item, getKontentImage } from '@lib/kontent'
import { RolexStorePageV72024 } from '@lib/kontent/models/RolexStorePageV72024'
import { StorePage } from '@lib/kontent/models/StorePage'

export function getStoreSchemaDetails(
  variant: 'gassan-store' | 'rolex-store',
  data: Item<StorePage> | Item<RolexStorePageV72024>,
  language: string,
): any {
  // StorePage and RolexStore have different property names for the name and image
  // @ts-ignore
  const name = data.elements?.title?.value ?? data.elements?.name?.value
  // @ts-ignore
  const image = data.elements?.image?.value[0]?.url ?? data.elements?.store_image?.value[0]?.url
  const url =
    variant === 'gassan-store'
      ? `https://gassan.com/${language}/${getUrlSlugInLocale('stores', language)}/${
          data.elements.url_slug.value
        }`
      : `https://gassan.com/${language}/rolex/contact/${data.elements.url_slug.value}`

  let schema = {
    '@context': 'https://schema.org',
    '@type': 'JewelryStore',
    name,
    image: getKontentImage(image, { width: 600 }),
    '@id': '',
    url,
    telephone: data.elements.phone_number.value,
  }

  if (
    data.elements.street_address.value &&
    data.elements.postal_code.value &&
    data.elements.city.value &&
    data.elements.country_code.value
  ) {
    // @ts-ignore
    schema['address'] = {
      '@type': 'PostalAddress',
      streetAddress: data.elements.street_address.value,
      postalCode: data.elements.postal_code.value,
      addressLocality: data.elements.city.value,
      addressCountry: data.elements.country_code.value,
    }
  }

  const groupedOpeninghours = getGroupedOpeningHours({ data })

  if (groupedOpeninghours.length > 0) {
    // @ts-ignore
    schema['openingHoursSpecification'] = groupedOpeninghours.map((openinghour) => ({
      '@type': 'OpeningHoursSpecification',
      dayOfWeek: openinghour.days.join(', '),
      opens: openinghour.from,
      closes: openinghour.till,
    }))
  }

  return schema
}
