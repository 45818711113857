// @ts-strict-ignore
import { Box, CleanButton, Icon, Text, breakpoints, theme } from '@gassan-ui'
import { AnimatePresence, motion } from 'framer-motion'
import { FC } from 'react'
import styled from 'styled-components'

type Props = {
  id?: string
  title: string
  description: string
  isActive: boolean
  onSelect: () => void
}

const Button = styled(CleanButton)`
  padding: 1.25rem 0;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: ${breakpoints.small}) {
    padding: 2rem 0;
  }

  @media screen and (min-width: ${breakpoints.large}) {
    padding: 1.5rem 0;
  }
`

const CollapsibleText: FC<Props> = ({ id, title, description, isActive, onSelect }) => (
  <Box
    borderBottom={`1px solid ${theme.colors.shades[300]}`}
    id={id && id}
    color={isActive && 'tabaccoBrown'}
  >
    <Button onClick={onSelect}>
      <Text mb="0" color={isActive && 'tabaccoBrown'}>
        {title}
      </Text>
      <Icon icon={isActive ? 'chevron-up' : 'chevron-down'} />
    </Button>
    <AnimatePresence initial={false}>
      {isActive && (
        <motion.div
          initial={{ opacity: 0, height: 0 }}
          animate={{ opacity: 1, height: 'auto' }}
          exit={{ opacity: 0, height: 0 }}
          transition={{ duration: 0.2, ease: [0.04, 0.62, 0.23, 0.98] }}
        >
          <Text maxWidth="43rem" mb="0" dangerouslySetInnerHTML={{ __html: description }}></Text>
          <Box height={{ _: '1.25rem', small: '1.5rem', large: '2rem' }} />
        </motion.div>
      )}
    </AnimatePresence>
  </Box>
)

export default CollapsibleText
