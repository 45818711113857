import { AddToPreferencesButton } from '@components/_account/AddToPreferencesButton'
import { Box, Grid, Heading, Image, Link, Text } from '@gassan-ui'
import { useAuth } from '@lib/hooks/use-auth'
import { getKontentImage } from '@lib/kontent'
import { useTranslation } from 'next-i18next'
import { FC } from 'react'

type Props = {
  title: string
  address: string
  directionsUrl: string
  preferenceNumber?: number
  email: string
  phone: string
  image: string
}

const StoreDetailHeader: FC<Props> = ({
  title,
  preferenceNumber,
  address,
  directionsUrl,
  email,
  phone,
  image,
}) => {
  const { isLoggedIn, isLoading } = useAuth()
  const showStorePreferenceButton = !isLoading && isLoggedIn && typeof preferenceNumber === 'number'
  const { t } = useTranslation('other')
  return (
    <Grid
      gridTemplateColumns={{ _: '1fr', large: '1fr 1fr' }}
      gridGap="2rem"
      mb={{ _: '3rem', small: '3.5rem', large: '5.5rem', xlarge: '7.5rem' }}
    >
      <Box>
        <Heading variant="h1" as="h1">
          {title}
        </Heading>
        <Grid gridTemplateColumns={{ _: '1fr', small: '1fr 1fr', large: '1fr' }}>
          <Box mb={{ _: '2rem', small: '0', large: '4rem' }}>
            <Text mb="0" variant="regularBold">
              {t('address')}
            </Text>
            <Text style={{ whiteSpace: 'pre-wrap' }}>{address}</Text>
            <Link variant="brown" target="blank" href={directionsUrl}>
              {t('directions')}
            </Link>
            {showStorePreferenceButton && (
              <Box mt="1rem">
                <AddToPreferencesButton variant="stores" preferenceNumber={preferenceNumber} />
              </Box>
            )}
          </Box>
          {(phone || email) && (
            <Box>
              <Text mb="0" variant="regularBold">
                Contact
              </Text>
              <Text mb="0">
                {t('callUsAt')}
                <a href={`tel:${phone}`}>{phone}</a>
              </Text>
              <Text>
                {t('emailQuestionTo')}
                <a href={`mailto:${email}`}>{email}</a>
              </Text>
            </Box>
          )}
        </Grid>
      </Box>
      <Box display={{ _: ' none', large: 'contents' }}>
        <Image
          alt={title}
          sources={[
            getKontentImage(image, { width: 1 }),
            getKontentImage(image, { width: 1 }),
            getKontentImage(image, { width: 900 }),
          ]}
          placeholderSrc={getKontentImage(image, { width: 50 })}
          className="aspect-[20/11]"
        />
      </Box>
    </Grid>
  )
}

export default StoreDetailHeader
