export const getAmsterdamTimezone = (): 'CEST' | 'CET' => {
  // Local time in Amsterdam is CEST during summertime, and CET during wintertime
  // We use the 28th of march as switch date for summertime
  // And the 25th of october as switch date for wintertime
  // Both values could be off by ~1 in the future
  const today = new Date()
  const cestSwitchDate = new Date(today.getFullYear(), 2, 28)
  const cetSwitchDate = new Date(today.getFullYear(), 9, 25)
  const currentTimeZone = today > cestSwitchDate && today < cetSwitchDate ? 'CEST' : 'CET'

  return currentTimeZone
}
