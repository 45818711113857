import { BreadcrumbItem } from '@components/Breadcrumbs'

export function getBreadcrumbSchemaDetails(data: BreadcrumbItem[]): any {
  return {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: data.map((item, index) => ({
      '@type': 'ListItem',
      position: index + 1,
      name: item.label,
      item: `https://gassan.com${item.href}`,
    })),
  }
}
