import { Item, getKontentImage } from '@lib/kontent'
import { RolexProductPageV72024 } from '@lib/kontent/models/RolexProductPageV72024'

export function getRolexProductSchemaDetails(data: Item<RolexProductPageV72024>): any {
  return {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: data.elements.title.value,
    image: getKontentImage(data.elements.main_image.value[0].url, { width: 750 }),
    description: data.elements.short_description.value,
    brand: {
      '@type': 'Brand',
      name: 'Rolex',
    },
  }
}
