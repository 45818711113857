import { Item } from '@lib/kontent'
import { RolexStorePageV72024 } from '@lib/kontent/models/RolexStorePageV72024'
import { StorePage } from '@lib/kontent/models/StorePage'

type GroupedOpeninghours = {
  from: string
  till: string
  days: string[]
}

type Props = {
  data: Item<RolexStorePageV72024> | Item<StorePage>
}

export function getGroupedOpeningHours({ data }: Props): GroupedOpeninghours[] {
  const { monday, tuesday, wednesday, thursday, friday, saturday, sunday } = data.elements
  const array = [monday, tuesday, wednesday, thursday, friday, saturday, sunday]
  const weekDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday']

  let groupedOpeninghours: GroupedOpeninghours[] = []

  array.forEach((day, index) => {
    if (day.value.includes('-') && day.value !== '-') {
      const [from, till] = day.value.replace(/\s/g, '').split('-')
      const alreadyGroupedIndex = groupedOpeninghours.findIndex(
        (group) => group.from === from && group.till === till,
      )

      if (alreadyGroupedIndex === -1) {
        groupedOpeninghours.push({
          from,
          till,
          days: [weekDays[index]],
        })
      } else {
        groupedOpeninghours[alreadyGroupedIndex].days.push(weekDays[index])
      }
    }
  })

  return groupedOpeninghours
}
