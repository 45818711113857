import { getBreadcrumbSchemaDetails } from './helpers/get-breadcrumb-schema-details'
import { getGassanProductSchemaDetails } from './helpers/get-gassan-product-schema-details'
import { getRolexProductSchemaDetails } from './helpers/get-rolex-product-schema-details'
import { getStoreSchemaDetails } from './helpers/get-store-schema-details'
import { BreadcrumbItem } from '@components/Breadcrumbs'
import { Article } from '@generated'
import { Item } from '@lib/kontent'
import { RolexProductPageV72024 } from '@lib/kontent/models/RolexProductPageV72024'
import { RolexStorePageV72024 } from '@lib/kontent/models/RolexStorePageV72024'
import { StorePage } from '@lib/kontent/models/StorePage'
import { useTranslation } from 'next-i18next'
import Script from 'next/script'
import { FC } from 'react'

type GassanStoreVariant = {
  variant: 'gassan-store'
  data: Item<StorePage>
}

type RolexStoreVariant = {
  variant: 'rolex-store'
  data: Item<RolexStorePageV72024>
}

type BreadcrumbVariant = {
  variant: 'breadcrumb'
  data: BreadcrumbItem[]
}

type RolexProductVariant = {
  variant: 'rolex-product'
  data: Item<RolexProductPageV72024>
}

type GassanProductVariant = {
  variant: 'gassan-product'
  data: Article
}
export type SchemaOrgProps =
  | GassanStoreVariant
  | RolexStoreVariant
  | BreadcrumbVariant
  | RolexProductVariant
  | GassanProductVariant

export const SchemaOrg: FC<SchemaOrgProps> = ({ data, variant }) => {
  const {
    i18n: { language },
  } = useTranslation('other')

  let schema = {}

  if (variant === 'gassan-store' || variant === 'rolex-store') {
    schema = getStoreSchemaDetails(variant, data, language)
  }

  if (variant === 'breadcrumb') {
    schema = getBreadcrumbSchemaDetails(data)
  }

  if (variant === 'rolex-product') {
    schema = getRolexProductSchemaDetails(data)
  }

  if (variant === 'gassan-product') {
    schema = getGassanProductSchemaDetails(data)
  }

  return (
    <Script
      type="application/ld+json"
      dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }}
    />
  )
}
