// @ts-strict-ignore
import FeaturedBrands from '@components/FeaturedBrands'
import { FloatingServiceMenu, Footer, Header, Page, PageTransition } from '@components/Page'
import { pickSeoProps } from '@components/Page/Page'
import AllBrands from '@components/_store-detail/AllBrands'
import StoreDetailHeader from '@components/_store-detail/Header'
import OpeningHours from '@components/_store-detail/OpeningHours'
import Services from '@components/_store-detail/Services'
import { SchemaOrg } from '@components/schema-org'
import { Box, Container, Grid } from '@gassan-ui'
import { buildLanguageAlternateSlugItems } from '@lib/build-language-alternates'
import { getServerSideTranslations } from '@lib/get-server-side-translations'
import { MultipleItemResponse, SingleItemResponse, fetchContent } from '@lib/kontent'
import { getPageByTypeAndUrlSlug } from '@lib/kontent/getPageByTypeAndUrlSlug'
import { StorePage as StorePageModel } from '@lib/kontent/models/StorePage'
import { GetStaticPaths, GetStaticProps } from 'next'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import { FC } from 'react'

export type Service = {
  codename: string
  title: string
  description: string
  descriptionNew: string
}

export type Brands = {
  title: string
  url_slug: string
}

export type RegularDays =
  | 'monday'
  | 'tuesday'
  | 'wednesday'
  | 'thursday'
  | 'friday'
  | 'saturday'
  | 'sunday'

export type SpecialDays = 'dayOne' | 'dayTwo' | 'dayThree' | 'dayFour'

export type RegularHours = {
  [key in RegularDays]: string
}

export type SpecialHours = {
  [key in SpecialDays]: {
    title: string
    value: string
  }
}

type Props = SingleItemResponse<StorePageModel> & {}

const StorePage: FC<Props> = ({ item, modular_content }) => {
  const { t } = useTranslation('other')
  const { locales } = useRouter()
  const services = item.elements.services.value.map((key) => ({
    codename: modular_content[key].system.codename,
    title: modular_content[key].elements.title.value,
    description: modular_content[key].elements.description.value,
    descriptionNew: modular_content[key].elements.description___new.value,
  }))
  const brands: Brands[] = item.elements.brands.value.map((key) => ({
    title: modular_content[key].elements.title.value,
    url_slug: modular_content[key].elements.url_slug.value,
  }))
  const featuredBrands = item.elements.featured_brands.value.map(
    (key) => modular_content[key].elements,
  )
  const {
    monday,
    tuesday,
    wednesday,
    thursday,
    friday,
    saturday,
    sunday,
    special_one_title,
    special_one_label,
    special_two_label,
    special_two_title,
    special_three_title,
    special_three_label,
    special_four_label,
    special_four_title,
  } = item.elements

  const regularHours: RegularHours = {
    monday: monday.value,
    tuesday: tuesday.value,
    wednesday: wednesday.value,
    thursday: thursday.value,
    friday: friday.value,
    saturday: saturday.value,
    sunday: sunday.value,
  }
  const specialHours: SpecialHours = {
    dayOne: {
      title: special_one_title.value,
      value: special_one_label.value,
    },
    dayTwo: {
      title: special_two_title.value,
      value: special_two_label.value,
    },
    dayThree: {
      title: special_three_title.value,
      value: special_three_label.value,
    },
    dayFour: {
      title: special_four_title.value,
      value: special_four_label.value,
    },
  }

  return (
    <Page
      {...pickSeoProps(item.elements)}
      languageAlternates={buildLanguageAlternateSlugItems(
        locales,
        'stores',
        null,
        `/${item.elements.url_slug.value}`,
      )}
    >
      <SchemaOrg variant="gassan-store" data={item} />
      <Header initialVariant="absolute" />
      <FloatingServiceMenu />
      <PageTransition>
        <Container my={{ _: '2rem', small: '3rem', large: '4rem' }}>
          <StoreDetailHeader
            title={item.elements.title.value}
            address={item.elements.address.value}
            directionsUrl={item.elements.directions_url.value}
            preferenceNumber={item.elements.daf_preference_number.value}
            email={item.elements.email.value}
            phone={item.elements.phone_number.value}
            image={item.elements.image.value[0]?.url}
          />
          <Grid
            gridTemplateColumns={{ _: '1fr', large: '1fr 1fr' }}
            gridColumnGap="2rem"
            gridRowGap={{ _: '1.5rem', small: '3.5rem' }}
            mb={{ _: '2.25rem', small: '2.75rem', large: '4.25rem', xlarge: '6.25rem' }}
          >
            <OpeningHours
              isNl={item.elements.country.value[0]?.codename === 'netherlands'}
              openingHoursOverwrite={item.elements.overwrite_opening_hours.value}
              regularHours={regularHours}
              specialHours={specialHours}
            />
            <Services items={services} />
          </Grid>
        </Container>
        {featuredBrands.length > 0 && (
          <Box mb={{ _: '4.25rem', small: '3.5rem', large: '6rem', xlarge: '7.5rem' }}>
            <FeaturedBrands titleVariant="h3" title={t('featuredBrands')} items={featuredBrands} />
          </Box>
        )}
        <AllBrands items={brands} />
        <Footer />
      </PageTransition>
    </Page>
  )
}

export default StorePage

export const getStaticProps: GetStaticProps = async ({ locale, params, preview }) => {
  const urlSlug = typeof params.slug === 'string' ? params.slug : params.slug.join('/')
  const content = await getPageByTypeAndUrlSlug<StorePageModel>({
    language: locale,
    type: 'store_page',
    urlSlug,
    preview,
  })
  return {
    props: {
      ...content,
      ...(await getServerSideTranslations(locale)),
    },
  }
}

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  let paths = []
  for (const locale of locales) {
    // Get store detail pages
    const storePages: MultipleItemResponse<StorePageModel> = await fetchContent({
      path: '',
      params: {
        elements: ['url_slug'],
        'system.type[in]': ['store_page'],
        language: locale,
      },
    })

    storePages.items.forEach((item) => {
      if (item.system.language === locale) {
        paths.push({
          params: {
            slug: [item.elements.url_slug.value],
          },
          locale,
        })
      }
    })
  }

  return { paths, fallback: false }
}
